import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type {
  EnvironmentSalesAgent,
  EnvironmentSalesAgentShared,
} from '../../../../../../libs/env/src/index';
import { env as envSalesDev } from '../env.dev';
import { env as baseEnv } from './env';

/** DON'T USE IN ANY MODULES. THIS IS WHAT `@ecp/env` ALIAS RESOLVES TO AT BUILD TIME. */
export const env: EnvironmentSalesAgent = merge({}, envSalesDev, baseEnv, {
  optimizely: {
    sdkKey: '8LU7qyvqHKGRH22swrNpv',
  },
} as EnvironmentSalesAgentShared) as EnvironmentSalesAgent;
