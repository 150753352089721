// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../../libs/env/src/index';

export const env: EnvironmentSalesAgentShared = {
  static: {
    expId: '2795',
    // TODO Remove once TODO for afe-partner-id header in libs/features/sales/shared/utils/network/src/requestWithToken.ts in addressed
    partnerId: '2795',
    title: 'Amfam Exclusive',
    partnerNameException: 'amfam-excl-agent',
  },
  afiAppname: 'amfam-excl-agent',
  afiApiKey: '00db3987-fdb1-4195-897c-1f6f7ac3c894',
  azureClientId: '40dcc05c-0849-4416-8ab1-dbe75da61bed',
  azureAuthority: 'https://login.microsoftonline.com/b6b2f512-6fd0-42fe-9659-6c5ee67da460',
  azureRedirectEndpoint: 'quote/landing',
  sapiClientCredentials: {
    // Amfam Exclusive
    '2795': {
      sapiClientId: 'rN6LSc6AlYVWRSOYdEjFCufrfKiLKWA8',
      sapiClientSecret: '8GYvZDWlSeizetcDk7B4e_Ehzzqw6k2ZiYiUKtmnSDUnkd8IWNQnA9zyJo_sAh27',
    },
  },
};
