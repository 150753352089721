import type { EnvironmentSalesConsumerShared } from './types';

/** Shared across sales applications and all runtime environments. */
export const env: EnvironmentSalesConsumerShared = {
  // TODO - Remove these 3 datadog ids after EDSP-43 is completed
  datadogApplicationId: '551a1557-30ca-4dfc-946c-171339bd2fdb',
  datadogClientToken: 'pub4807e8a896c0cc64d1ad8c985cbbd2fa',
  datadogService: 'ecp-ui-sales',
  datadogAllowedTracingOrigins: [
    /https:\/\/dal.ecp.*\.homesitep2\.com/,
    /https:\/\/.*salesapi\.homesitep2\.com/,
    /https:\/\/.*agencyauthv2\.homesitep2\.com/,
    /https:\/\/configservice\.partner.*\.homesitep2\.com/,
    /https:\/\/.*sapianalytics\.homesitep2\.com/,
    /https:\/\/.*costcomembership\.homesitep2\.com/,
    /https:\/\/.*quoteemail\.homesitep2\.com/,
  ],
  gtmId: 'GTM-P4FG8Q8',
  tokenApiUrl: 'https://p2homesite.auth0.com/oauth/token',
  audience: 'https://salesapi-v2.homesitep2.com',
  amfamBillingAfiAppname: 'ChoicePlatform',
  static: {},
  optimizely: {
    proxyEnabled: true,
  },
};
